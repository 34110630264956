// router.js
import Vue from 'vue'
import VueRouter from 'vue-router'
// 越南namvaytien
// import MyFirst from './namvaytien/MyFirst.vue'
// import MyAbout from './namvaytien/MyAbout.vue'
// import MyProtocol from './namvaytien/MyProtocol.vue'
// import MyQuestion from './namvaytien/MyQuestions.vue'

//泰国chatchaimobile
// import MyFirst from './chatchaimobile/MyFirst.vue'
// import MyProtocol from './chatchaimobile/MyProtocol.vue'


//泰国jaidee
// import MyFirst from './jaidee/MyFirst.vue'
// import MyProtocol from './jaidee/MyProtocol.vue'
// import MyService from './jaidee/MyService.vue'
// import MyQuestion from './jaidee/MyQuestions.vue'
//泰国第二个jaidee
// import MyFirst from './weimingm/MyFirst.vue'
// import MyProtocol from './weimingm/MyProtocol.vue'
// import MyAbout from './weimingm/MyAbout.vue'
// import MyClause from './weimingm/MyClause.vue'

//越南kasikvay
// import MyFirst from './kasikvay/MyFirst.vue'

// import MyAbout from './kasikvay/MyAbout.vue'
// import MyClause from './kasikvay/MyClause.vue'
// import MyQuestions from './kasikvay/MyQuestions.vue'
// import MyProtocol from './kasikvay/MyProtocol.vue'


//越南tamogiefectivo
// import MyFirst from './tamogiefectivo/MyFirst.vue'
// import MyProtocol from './tamogiefectivo/MyProtocol.vue'
// import MyAbout from './tamogiefectivo/MyAbout.vue'
// import MyQuestion from './tamogiefectivo/MyQuestions.vue'
// import MyCenter from './tamogiefectivo/MyCenter.vue'

//墨西哥consedeefectivo
// import MyFirst from './consedeefectivo/MyFirst.vue'
// import MyAbout from './consedeefectivo/MyAbout.vue'
// import MyService from './consedeefectivo/MyService.vue'
// import MyProtocol from './consedeefectivo/MyProtocol.vue'

//墨西哥betacreditdinero
// import MyFirst from './betacreditdinero/MyFirst.vue'
// import MyAbout from './betacreditdinero/MyAbout.vue'
// import MyContact from './betacreditdinero/MyContact.vue'
// import MyProtocol from './betacreditdinero/MyProtocol.vue'

//墨西哥cherrypre
// import MyFirst from './cherreypréstamos/MyFirst.vue'
// import MyAbout from './cherreypréstamos/MyAbout.vue'
// import MyService from './cherreypréstamos/MyService.vue'
// import MyQuestions from './cherreypréstamos/MyQuestions.vue'
// import MyProtocol from './cherreypréstamos/MyProtocol.vue'

//越南nathcopay
// import MyFirst from './nathcopay/MyFirst.vue'
// import MyQuestions from './nathcopay/MyQuestions.vue'
// import MyProtocol from './nathcopay/MyProtocol.vue'

//越南mrVaymobile
// import MyFirst from './mrVaymobile/MyFirst.vue'
// import MyProtocol from './mrVaymobile/MyProtocol.vue'
// import MyAbout from './mrVaymobile/MyAbout.vue'
// import MyContact from './mrVaymobile/MyContact.vue'

//越南hcthlogplus
// import MyFirst from './hcthlogplus/MyFirst.vue'
// import MyProtocol from './hcthlogplus/MyProtocol.vue'
// import MyAbout from './hcthlogplus/MyAbout.vue'
// import MyProcess from './hcthlogplus/MyProcess.vue'


//越南hipotecariabilletera
// import MyFirst from './hipotecariabilletera/MyFirst.vue'
// import MyProtocol from './hipotecariabilletera/MyProtocol.vue'
// import MyPaso from './hipotecariabilletera/MyPaso.vue'
// import MyProcess from './hipotecariabilletera/MyQuestions.vue'
// import MyContact from './hipotecariabilletera/MyContact.vue'

// 越南sunmobile
// import MyFirst from './sunmobile/MyFirst.vue'
// import MyProtocol from './sunmobile/MyProtocol.vue'
// import MyPaso from './sunmobile/MyPaso.vue'
// import MyQuestions from './sunmobile/MyQuestions.vue'
// import MyContact from './sunmobile/MyContact.vue'

// 越南cathaydonge
// import MyFirst from './cathaydong/MyFirst.vue'
// import MyProtocol from './cathaydong/MyProtocol.vue'
// import MyPaso from './cathaydong/MyPaso.vue'
// import MyQuestions from './cathaydong/MyQuestions.vue'
// import MyService from './cathaydong/MyService.vue'


// 越南newvay
import MyFirst from './newvay/MyFirst.vue'
import MyProtocol from './newvay/MyProtocol.vue'
import MyQuestions from './newvay/MyQuestions.vue'
import MyAbout from './newvay/MyAbout.vue'
import MyService from './newvay/MyService.vue'

//墨西哥eldefiardinero
// import MyFirst from './eldefiardinero/MyFirst.vue'
// import MyProtocol from './eldefiardinero/MyProtocol.vue'
// import MyQuestions from './eldefiardinero/MyQuestions.vue'
// import MyAbout from './eldefiardinero/MyAbout.vue'
// import MyPaso from './eldefiardinero/MyNews.vue'


//墨西哥thanhchungplus
// import MyFirst from './thanhchungplus/MyFirst.vue'
// import MyProtocol from './thanhchungplus/MyProtocol.vue'
// import MyQuestions from './thanhchungplus/MyQuestions.vue'
// import MyAbout from './thanhchungplus/MyAbout.vue'
// import MyPaso from './thanhchungplus/MyNews.vue'


//墨西哥dealcorpefectivo
// import MyFirst from './dealcorpefectivo/MyFirst.vue'
// import MyProtocol from './dealcorpefectivo/MyProtocol.vue'
// import MyService from './dealcorpefectivo/MyService.vue'
// import MyContact from './dealcorpefectivo/MyContact.vue'


//墨西哥kampalacash
// import MyFirst from './kampalacash/MyFirst.vue'
// import MyProtocol from './kampalacash/MyProtocol.vue'
// import MyService from './kampalacash/MyService.vue'
// import MyAbout from './kampalacash/MyAbout.vue'

Vue.use(VueRouter)
//越南namvaytien
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/about',
//         component: MyAbout, // /list 路由
//       },
   
//   ]

//泰国chatchaimobile
  // const routes = [
  //   {
  //     path: '/',
  //     component: MyFirst, // 默认路由
  //   },
   
  //   {
  //     path: '/protocol',
  //     component: MyProtocol, // protocol 路由
  //   },
  // ]

//泰国jaidee
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/service',
//         component: MyService, // /list 路由
//       },
   
//   ]
//泰国第二个jaidee
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/clause',
//       component: MyClause, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/about',
//         component: MyAbout, // /list 路由
//       },
   
//   ]
//越南mrVaymobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/contact',
//     component: MyContact, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
 
// ]

//越南kasikvay
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
    
//     {
//       path: '/clause',
//       component: MyClause, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
  
   
//   ]

//越南tamogiefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestion, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
//     {
//       path: '/center',
//       component: MyCenter, // /list 路由
//     },
 
 
// ]

//墨西哥consedeefectivo
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
    
 
//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
  
   
//   ]


// 越南betacreditdinero
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
    
  
//     {
//       path: '/contant',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
  
   
//   ]
// 越南nathcopay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // /list 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

 
// ]


// 
//墨西哥hipotecariabilletera
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/process',
//     component: MyProcess, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/paso',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
// ]


//越南sunmobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/paso',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
// ]

//越南cathaydonge
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
// ]


//越南newvay
const routes = [
  {
    path: '/',
    component: MyFirst, // 默认路由
  },
  {
    path: '/questions',
    component: MyQuestions, // 默认路由
  },
  {
    path: '/protocol',
    component: MyProtocol, // product 路由
  },
  {
      path: '/about',
      component: MyAbout, // /list 路由
    },
    {
      path: '/service',
      component: MyService, // /list 路由
    },
]

//墨西哥eldefiardinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/support',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/news',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]


//越南thanhchungplus
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/support',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/news',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]


//越南Dealcorp Efectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
// ]


//墨西哥kampalacash
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

const router = new VueRouter({
  mode: 'hash', // 设置路由模式为 hash
  routes
})

export default router
